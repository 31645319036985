// GlobalLoader.tsx
import React from "react";
import { useLoading } from "../context/LoadingContext";
import { BeatLoader } from "react-spinners";

const GlobalLoading: React.FC = () => {
  const { loading } = useLoading();

  return loading ? (
    <div className="loading-container">
      <BeatLoader color="#36d7b7" loading={loading} size={15} />
    </div>
  ) : null;
};

export default GlobalLoading;
