import moment from "moment";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Calendar from "react-calendar";
import { useNavigate } from "react-router-dom";
import HabitStampApi from "../../api/HabitStampApi";
import Button from "../../components/Button";
import { HabitInfoDto } from "../../dto/HabitInfoDto";
import { StampDto } from "../../dto/StampDto";

// 아래 css 로딩 순서는 바뀌면 안됨.
// react-calender default css를 불러온 후 main.css가 오버라이딩 되어야함.
import "react-calendar/dist/Calendar.css";
import "./Main.css";
import { useTransition } from "react-spring";
import Modal from "../../components/Modal";
import { useLoading } from "../../context/LoadingContext";

const Main: React.FC = () => {
  const navigate = useNavigate();

  const [stampList, setStampList] = useState<Array<StampDto>>([]);
  const [habitInfo, setHabitInfo] = useState<HabitInfoDto>();
  const [mbrNo, setMbrNo] = useState<number>(0);
  // 최초 외에는 항상 보여주는 월의 1일이 들어가있다 (8월달력이면 20230801)
  const [currentDate, setCurrentDate] = useState<string>(moment().format("YYYYMMDD"));
  type ValuePiece = Date | null;
  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const [selectedDate, setSelectedDate] = useState<Value>(new Date());

  // AWS Lambda Migration
  const [habitName, setHabitName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>(localStorage.getItem("phoneNumber") || "정보없음");

  // Modal 적용 (react-spring)
  const [modalVisible, setModalVisible] = useState(false);
  const { loading, setLoading } = useLoading(); // loading

  useEffect(() => {
    if (!localStorage.getItem("phoneNumber")) {
      alert("정보없음!");
      goIntroPage();
    }

    getHbtInfo();
  }, []);

  useEffect(() => {
    getListAllStamp();
    console.log(`currentDate:`, currentDate);

    initBackgroundColor();
  }, [currentDate]);

  useEffect(() => {
    changeStampColor();
  }, [stampList]);

  useEffect(() => {
    console.log(moment(selectedDate?.toString()).format("YYYYMMDD"));
  }, [selectedDate]);

  const goIntroPage = () => {
    navigate("/", { replace: true });
  };

  const getHbtInfo = () => {
    HabitStampApi.getHbtInfo_lambda(phoneNumber).then((response) => {
      setHabitName(response.data.habitName);
    });
  };

  const getListAllStamp = () => {
    setLoading(true);
    HabitStampApi.getListAllStamp_lambda(phoneNumber)
      .then((response) => {
        console.log("getListAllStamp_lambda", response);

        // 응답받은 response.data는 중복이 있는 stampList임
        // 날짜별 가장 최신 도장 하나만 들어가도록 필터링 해서 stampList state에 set해야됨
        const tempStampList: Array<StampDto> = [];
        response.data.map((item: StampDto) => {
          if (tempStampList.some((tempStamp) => tempStamp.stampDttm.substring(0, 8) == item.stampDttm.substring(0, 8)))
            return;

          tempStampList.push(item);
        });
        setStampList([...(tempStampList || [])]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initBackgroundColor = () => {
    // 모든 'abbr' 태그를 찾습니다.
    const abbrElements = document.querySelectorAll("abbr");
    abbrElements.forEach((abbr) => {
      // abbr 요소의 부모인 button 요소를 찾습니다.
      const parentButton = (abbr as HTMLElement).closest("button");
      if (parentButton) {
        (parentButton as HTMLElement).style.backgroundColor = "white"; // 배경색을 업데이트
        (parentButton as HTMLElement).style.borderRadius = "50%";
      }
    });
  };

  const parseDate = (dateStr: string) => {
    // 한국어 날짜 문자열을 처리하기 위한 정규 표현식
    const regex = /(\d{4})년\s(\d{1,2})월\s(\d{1,2})일/;
    const match = dateStr.match(regex);

    if (match) {
      const [_, year, month, day] = match;
      // moment 객체를 생성하고 원하는 형식으로 포맷합니다.
      return moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format("YYYYMMDD");
    }
  };

  const changeStampColor = () => {
    stampList.map((item) => {
      if (item.stampDttm.substring(0, 6) != moment(currentDate).format("YYYYMM")) {
        return;
      }

      // 모든 'abbr' 태그를 찾습니다.
      const abbrElements = document.querySelectorAll("abbr");
      abbrElements.forEach((abbr) => {
        // abbr 요소의 aria-label 속성을 가져옵니다.
        const ariaLabel = (abbr as HTMLElement).getAttribute("aria-label") || "2000년 1월 1일";
        const parseAriaLabel = parseDate(ariaLabel);
        if (parseAriaLabel === item.stampDttm.substring(0, 8)) {
          // 예: 특정 날짜의 aria-label 값
          // abbr 요소의 부모인 button 요소를 찾습니다.
          const parentButton = (abbr as HTMLElement).closest("button");
          if (parentButton) {
            (parentButton as HTMLElement).style.backgroundColor = item.stampColor; // 배경색을 업데이트
            (parentButton as HTMLElement).style.borderRadius = "50%";
          }
        }
      });

      return;
    });
  };

  const onClickMenu = () => {
    alert("Please wait for update 💨");
  };

  const onClickRegistTodayStamp = () => {
    // if (window.confirm("Succeeded Today? 🤔")) {
    //   HabitStampApi.registStampInfo_lambda(phoneNumber)
    //     .then((response) => {
    //       console.log("registStampInfo_lambda", response);
    //       getListAllStamp();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else {
    //   return;
    // }
  };

  // currentDate의 전체 일수 (2월 : 28, 3월 : 31)
  const getThisMonthDays = (): number => {
    return moment(currentDate).daysInMonth();
  };

  // currentDate의 성공 일수 (받은 stampList 중 이번달 갯수 필터링)
  // 24.09.07 추가 : 도장색깔이 추가됨으로써, 특정 색상만 성공으로 count 해야됨
  const getThisMonthSuccessDay = (): number => {
    let currentDateMonthSuccess: Array<string> = [];
    stampList.map((item, index) => {
      if (item.stampColor != "#c2ffb0" && item.stampColor != "#FFDB76") return;

      // 현재 날짜로 필터
      if (item.stampDttm.startsWith(moment(currentDate).format("YYYYMM"))) {
        // 중복 제거
        if (!currentDateMonthSuccess.includes(item.stampDttm.substring(0, 8))) {
          currentDateMonthSuccess.push(item.stampDttm.substring(0, 8));
        }
      }
    });
    return currentDateMonthSuccess.length;
  };

  // currentDate의 진행중 일수
  const getThisMonthTryingDay = (): number => {
    // 이번달인 경우 : 이번달 일수 - 오늘날짜 + (오늘 성공안했으면 +1)
    if (moment(currentDate).format("YYYYMM") === moment().format("YYYYMM")) {
      let todaySuccess: boolean = false;
      stampList.map((item, index) => {
        if (item.stampDttm.substring(0, 8) === moment().format("YYYYMMDD")) {
          todaySuccess = true;
        }
      });
      return (
        moment(moment().format(`YYYYMM${getThisMonthDays()}`)).diff(moment().format("YYYYMMDD"), "days") +
        (todaySuccess ? 0 : 1)
      );
    }
    // 이번달 이후 : 항상 진행 중
    else if (moment(currentDate).format("YYYYMM") > moment().format("YYYYMM")) {
      return getThisMonthDays();
    }
    // 저번달 이전 : 항상 0
    else {
      return 0;
    }
  };

  // currentDate의 실패 일수
  const getThisMonthFailDay = (): number => {
    // 이번달인 경우 : 오늘날짜부터 1일까지의 차이 - 성공한 일수 + (오늘 성공이면 +1)
    if (moment(currentDate).format("YYYYMM") === moment().format("YYYYMM")) {
      let todaySuccess: boolean = false;
      stampList.map((item, index) => {
        if (item.stampDttm.substring(0, 8) === moment().format("YYYYMMDD")) {
          todaySuccess = true;
        }
      });
      return moment().diff(moment().format("YYYYMM01"), "days") - getThisMonthSuccessDay() + (todaySuccess ? 1 : 0);
    }
    // 다음달 이후 (항상 진행 중 : fail은 0)
    else if (moment(currentDate).format("YYYYMM") > moment().format("YYYYMM")) {
      return 0;
    }
    // 저번달 이전 : (전체 일수) - 성공한 일수
    else {
      return getThisMonthDays() - getThisMonthSuccessDay();
    }
  };

  // calendar 날짜 선택
  const handleDateChange = (value: Value) => {
    setSelectedDate(value);
  };

  return (
    <div className="main">
      <div className="header">
        <div className="menu-left" onClick={onClickMenu}></div>
        <div className="habit-title">{habitName}🔥</div>
      </div>

      <div className="calendar">
        <Calendar
          calendarType="gregory"
          minDetail="month"
          formatDay={(locale, date) => moment(date).format("D")}
          formatMonth={(locale, date) => moment(date).format("YYYY.MM")}
          onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
            setCurrentDate(moment(activeStartDate).format("YYYYMMDD"));
          }}
          onChange={handleDateChange}
          value={selectedDate}
          // tileClassName={({ date, view }) => {
          //   if (stampList.find((item) => item.stampDttm.substring(0, 8) === moment(date).format("YYYYMMDD"))) {
          //     return "stamp-success";
          //   }
          // }}
          // tileClassName={tileClassName}
          // tileContent={tileContent}
        />
      </div>

      <div className="status">
        <Chart
          options={{
            title: {
              text: "THIS MONTH",
              offsetX: 20,
              offsetY: 20,
              style: {
                color: "white",
              },
            },
            legend: {
              show: true,
              fontSize: "14px",
              offsetX: 0,
              offsetY: -3,
              labels: {
                colors: "white",
              },
              itemMargin: {
                horizontal: 10,
              },
              onItemClick: {
                toggleDataSeries: false,
              },
              onItemHover: {
                highlightDataSeries: false,
              },
            },
            chart: {
              type: "bar",
              height: "100%",
              stacked: true,
              stackType: "100%",
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            stroke: {
              width: 0.5,
              colors: ["#fff"],
            },
            xaxis: {
              labels: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            yaxis: {
              show: false,
              showAlways: false,
              showForNullSeries: false,
              labels: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            grid: {
              show: false,
            },
            fill: {
              opacity: 1,
            },
            colors: ["#0BC904", "#FFBB00", "#FF0000"],
            tooltip: {
              x: {
                show: false,
              },
              y: {
                formatter(val, opts) {
                  return val + "일";
                },
              },
            },
          }}
          series={[
            {
              name: "Succeed",
              data: [getThisMonthSuccessDay()], // 성공 일수
            },
            {
              name: "Trying",
              data: [getThisMonthTryingDay()],
            },
            {
              name: "Failed",
              data: [getThisMonthFailDay()], // 실패 일수
            },
          ]}
          type="bar"
          width={"96%"}
          height={"80%"}
        />
      </div>

      <div className="button-wrapper">
        {/* <Button className="today-success-button" onClick={onClickRegistTodayStamp}> */}
        <Button
          className="today-success-button"
          onClick={() => {
            // 오늘 이후 날짜의 도장을 찍는 경우 토스트 알림
            if (moment(selectedDate?.toString()).isAfter(moment())) {
              alert("오늘 이후의 날짜는 도장불가!");
              return;
            } else {
              setModalVisible(true);
            }
          }}
        >
          ✅ Stamp Today
        </Button>
        <Modal
          phoneNumber={phoneNumber}
          selectedDate={selectedDate}
          showModal={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            getListAllStamp();
          }}
        />
      </div>
    </div>
  );
};

export default Main;
