import React from "react";
import { useSpring, animated } from "react-spring";

import "./Modal.css"; // 스타일 정의
import HabitStampApi from "../api/HabitStampApi";
import { useLoading } from "../context/LoadingContext";
import { Value } from "react-calendar/dist/cjs/shared/types";
import moment from "moment";

interface ModalProps {
  phoneNumber: string;
  selectedDate: Value;
  showModal: boolean;
  closeModal: () => void;
}
const Modal = ({ phoneNumber, selectedDate, showModal, closeModal }: ModalProps) => {
  const { loading, setLoading } = useLoading();

  // useSpring을 통해 애니메이션 정의
  const modalAnimation = useSpring({
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-50%)`,
    config: { tension: 200, friction: 20 },
  });

  if (!showModal) return null;

  const registStamp = (stampDttm: string, stampColor: string): Promise<any> => {
    setLoading(true);
    return HabitStampApi.registStampInfo_lambda(phoneNumber, stampDttm, stampColor)
      .then((response) => {
        console.log("registStampInfo_lambda", response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="modal-backdrop" onClick={closeModal}>
      <animated.div style={modalAnimation} className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* X 버튼 */}
        <button className="close-button" onClick={closeModal}>
          &times;
        </button>

        <h2>{moment(selectedDate?.toString()).format("M월 D일")} 도장 색깔</h2>

        {/* 2x2 선택 상자 */}
        <div className="grid-container">
          <button
            className="grid-item red"
            onClick={() => {
              registStamp(moment(selectedDate?.toString()).format("YYYYMMDD"), "#FF9999").then(closeModal);
            }}
          ></button>
          <button
            className="grid-item yellow"
            onClick={() => {
              registStamp(moment(selectedDate?.toString()).format("YYYYMMDD"), "#FFDB76").then(closeModal);
            }}
          ></button>
          <button
            className="grid-item green"
            onClick={() => {
              registStamp(moment(selectedDate?.toString()).format("YYYYMMDD"), "#c2ffb0").then(closeModal);
            }}
          ></button>
          <button
            className="grid-item"
            onClick={() => {
              registStamp(moment(selectedDate?.toString()).format("YYYYMMDD"), "#FFFFFF").then(closeModal);
            }}
          ></button>
        </div>
      </animated.div>
    </div>
  );
};

export default Modal;
