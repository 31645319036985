import React from "react";
import { Route, Routes } from "react-router-dom";
import Intro from "./pages/Intro/Intro";
import Main from "./pages/Main/Main";
import { LoadingProvider } from "./context/LoadingContext";
import GlobalLoading from "./components/GlobalLoading";

const App: React.FC = () => {
  return (
    <div className="App">
      <LoadingProvider>
        <GlobalLoading />
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/main" element={<Main />} />
        </Routes>
      </LoadingProvider>
    </div>
  );
};

export default App;
