import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HabitStampApi from "../../api/HabitStampApi";
import Button from "../../components/Button";
import IntroForm from "./IntroForm";

import "./Intro.css";

const Intro: React.FC = () => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [memberNumber, setMemberNumber] = useState<string>("");
  const [habitName, setHabitName] = useState<string>("");

  useEffect(() => {
    // localStroage에 이미 정보(핸드폰 번호)가 있다면 main으로 이동
    if (localStorage.getItem("phoneNumber")) {
      goMainPage();
    }
  }, []);

  const goMainPage = () => {
    // /main 으로 이동
    navigate("/main", { replace: true });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    HabitStampApi.registHbt_lambda(memberNumber, habitName, name)
      .then((response) => {
        console.log(response); //{"success":true,"data":{"habitName":"123"},"message":"기존"}

        localStorage.setItem("phoneNumber", memberNumber);
        goMainPage();
      })
      .catch((error) => {
        console.log(error);
      });

    // HabitStampApi.registMbr(memberNumber ?? 0, name)
    //   .then((response) => {
    //     console.log("registMbr", response);

    //     // 실패(dup key)가 아닌 경우 습관등록 API 호출
    //     if (response.data !== "실패") {
    //       HabitStampApi.registHbt(memberNumber ?? 0, habitName).then((response) => {
    //         console.log("registHbt", response);

    //         localStorage.setItem("mbrNo", String(memberNumber));
    //         goMainPage();
    //       });
    //     }
    //     // 이미 등록된 사용자인 경우 localStorage에 등록 후 화면 이동
    //     else {
    //       localStorage.setItem("mbrNo", String(memberNumber));
    //       goMainPage();
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  return (
    <div className="intro-container">
      <h1 className="title">Habit Stamp</h1>

      <IntroForm
        id="intro-form"
        name={name}
        setName={setName}
        memberNumber={memberNumber}
        setMemberNumber={setMemberNumber}
        habitName={habitName}
        setHabitName={setHabitName}
        onSubmit={handleSubmit}
      />

      <Button className="submit-button" form="intro-form">
        Start
      </Button>
    </div>
  );
};

export default Intro;
