import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className: string;
}
const Button = ({ className, ...rest }: ButtonProps) => {
  return <button className={className} {...rest}></button>;
};

export default Button;
