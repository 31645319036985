import React from "react";

interface IntroFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  id: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  memberNumber: string;
  setMemberNumber: React.Dispatch<React.SetStateAction<string>>;
  habitName: string;
  setHabitName: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}
const IntroForm = ({
  id,
  name,
  setName,
  memberNumber,
  setMemberNumber,
  habitName,
  setHabitName,
  onSubmit,
}: IntroFormProps) => {
  return (
    <form id={id} className="form-wrapper" onSubmit={onSubmit}>
      <input
        type="text"
        placeholder="Nickname"
        value={name}
        onChange={(e) => {
          setName(e.currentTarget.value);
        }}
        required
      />
      <input
        type="number"
        placeholder="Phone Number"
        maxLength={11}
        value={memberNumber ?? ""}
        onChange={(e) => {
          setMemberNumber(e.currentTarget.value);
        }}
        // onInput={(e) => {
        //   if (e.currentTarget.value.length > 11) {
        //     e.currentTarget.value = e.currentTarget.value.slice(0, 11);
        //   }
        // }}
        required
      />
      <input
        className="height-2rem"
        type="text"
        placeholder="Your Habit"
        value={habitName}
        onChange={(e) => {
          setHabitName(e.currentTarget.value);
        }}
        required
      />
    </form>
  );
};

export default IntroForm;
