import axios from "axios";
import { BASE_URL, BASE_URL_LAMBDA } from "../common/Constant";

const axiosConfig = {
  method: "POST",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const registMbr = async (mbrNo: number, mbrNm: string) => {
  return axios({
    ...axiosConfig,
    url: `${BASE_URL}/registMbr`,
    data: {
      mbrNo,
      mbrNm,
    },
  }).then((res) => res.data);
};

const registHbt = async (mbrNo: number, hbtNm: string) => {
  return axios({
    ...axiosConfig,
    url: `${BASE_URL}/registHbt`,
    data: {
      mbrNo,
      hbtNm,
    },
  }).then((res) => res.data);
};

const registHbt_lambda = async (phoneNumber: string, habitName: string, memberName: string) => {
  // return axios({
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Content-Length": 100000,
  //   },
  //   url: `https://2q9dra82yl.execute-api.ap-northeast-2.amazonaws.com/PRD/registHbtInfo`,
  //   data: {
  //     phoneNumber,
  //     habitName,
  //     memberName,
  //   },
  // }).then((res) => res.data);

  const url = `${BASE_URL_LAMBDA}/registHbtInfo`;
  const data = {
    phoneNumber,
    habitName,
    memberName,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(url, data, { headers }).then((res) => res.data);
};

const getListAllStamp = async (mbrNo: number) => {
  return axios({
    ...axiosConfig,
    url: `${BASE_URL}/getListAllStamp`,
    data: {
      mbrNo,
    },
  }).then((res) => res.data);
};

const getListAllStamp_lambda = async (phoneNumber: string) => {
  const url = `${BASE_URL_LAMBDA}/getAllStampInfo`;
  const data = {
    phoneNumber,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(url, data, { headers }).then((res) => res.data);
};

const registTodayStamp = async (mbrNo: number) => {
  return axios({
    ...axiosConfig,
    url: `${BASE_URL}/registTodayStamp`,
    data: {
      mbrNo,
    },
  }).then((res) => res.data);
};

const registStampInfo_lambda = async (phoneNumber: string, stampDttm: string, stampColor: string) => {
  const url = `${BASE_URL_LAMBDA}/registStampInfo`;
  const data = {
    phoneNumber,
    stampDttm,
    stampColor,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(url, data, { headers }).then((res) => res.data);
};

const getHbtInfo = async (mbrNo: number) => {
  return axios({
    ...axiosConfig,
    url: `${BASE_URL}/getHbtInfo`,
    data: {
      mbrNo,
    },
  }).then((res) => res.data);
};

const getHbtInfo_lambda = async (phoneNumber: string) => {
  const url = `${BASE_URL_LAMBDA}/getHbtInfo`;
  const data = {
    phoneNumber,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(url, data, { headers }).then((res) => res.data);
};

const HabitStampApi = {
  registMbr,
  registHbt,
  registHbt_lambda,
  getListAllStamp,
  getListAllStamp_lambda,
  registTodayStamp,
  registStampInfo_lambda,
  getHbtInfo,
  getHbtInfo_lambda,
};

export default HabitStampApi;
